/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'aspect-ratio-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 12.5v-9A1.5 1.5 0 011.5 2h13A1.5 1.5 0 0116 3.5v9a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 12.5M2.5 4a.5.5 0 00-.5.5v3a.5.5 0 001 0V5h2.5a.5.5 0 000-1zm11 8a.5.5 0 00.5-.5v-3a.5.5 0 00-1 0V11h-2.5a.5.5 0 000 1z"/>',
    },
});
